import { FC, useRef } from 'react'
import { AwardProps, CardProps, SlideOutBenefitsProps } from './types'
import styles from './SlideOutBenefits.module.scss'
import { getDefaultAwards, getDefaultCards } from './utils'
import { Img } from 'components/basic/Img'
import { gsap } from 'gsap'
import Flip from 'gsap/dist/Flip'
import cx from 'classnames'
import { Button } from 'components/Phantom/Button'
import { LinkWithArrow } from 'components/basic/LinkWithArrow'
import { amClickedButton } from 'events/amplitude'

gsap.registerPlugin(Flip)

export const SlideOutBenefits: FC<SlideOutBenefitsProps> = (props) => {
	const { cards = getDefaultCards(), awards = getDefaultAwards(), title = 'Better sleep means better health', ctaUrl = '/product/pod-cover' } = props

	const cta = props.anchorHref ? (
		<div className={styles.slideout_cta_container}>
			<a href={props.anchorHref}>
				<Button.White
					id={'slideout-sleep-cta'}
					onClick={() => {
						amClickedButton('Slide out sleep CTA', 'slideout-sleep-cta')
					}}
				>
					Shop now
				</Button.White>
			</a>
		</div>
	) : (
		<Button.White
			className={styles.cta_button}
			id={'slideout-sleep-cta'}
			href={ctaUrl}
		>
			Shop now
		</Button.White>
	)

	return (
		<section className={styles.container}>
			<header className={styles.header}>
				<h2>{title}</h2>
			</header>

			<ul className={styles.card_list}>
				{cards.map((card) => {
					return (
						<li
							key={card.title}
							className={styles.card_wrapper}
						>
							<SlideOutCard {...card} />
						</li>
					)
				})}
			</ul>

			<h3 className={'vh'}>Awards</h3>

			<ul className={styles.awards_list}>
				{awards.map((award) => {
					return (
						<li
							key={award.title}
							className={styles.awards_wrapper}
						>
							<Award {...award} />
						</li>
					)
				})}
			</ul>
			<div className={styles.slideout_cta_container}>{cta}</div>
		</section>
	)
}

export const T1Benefits: FC<SlideOutBenefitsProps> = (props) => {
	const { cards = getDefaultCards(), awards = getDefaultAwards(), title = 'The Pod delivers better sleep, for better health' } = props

	return (
		<>
			<section className={`${styles.container2} ${props.className}`}>
				<header className={styles.header2}>
					<h2>{title}</h2>
					{props.cta && (
						<div className={styles.cta_container}>
							<Button.Primary
								id={'personal-sleep-cta'}
								href={'/product/pod-cover'}
							>
								Shop now
							</Button.Primary>
							<LinkWithArrow
								id={'personal-sleep-learn-more'}
								href={'/pod-cover'}
								color={'white'}
								className={styles.arrow_cta}
							>
								Learn more
							</LinkWithArrow>
						</div>
					)}
				</header>

				<ul className={styles.card_columns}>
					{cards.map((card) => {
						return (
							<li
								key={card.title}
								className={styles.card}
							>
								<Img
									src={card.img_src}
									alt={card.image.alt}
								/>
								<div>
									<p className={styles.small_tag}>{card.tag}</p>
									<p className={styles.blurb}>{card.blurb}</p>
								</div>
							</li>
						)
					})}
				</ul>
			</section>
			{props.hideAwards ? (
				<></>
			) : (
				<section className={styles.awards_section}>
					<h3 className={styles.awards_section_title}>Awards</h3>
					<ul className={styles.awards_list}>
						{awards.map((award) => {
							return (
								<li
									key={award.title}
									className={styles.awards_wrapper}
								>
									<Award {...award} />
								</li>
							)
						})}
					</ul>
				</section>
			)}
		</>
	)
}

const SlideOutCard: FC<CardProps> = (props) => {
	const articleRef = useRef<HTMLDivElement>(null)
	const imageWrapperRef = useRef<HTMLDivElement>(null)

	return (
		<article
			className={styles.card_inner}
			ref={articleRef}
		>
			<div
				className={styles.image_wrapper}
				ref={imageWrapperRef}
			>
				<Img
					src={props.image.src}
					alt={props.image.alt}
					objectFit="cover"
					dprHeight={1000}
				/>

				<div className={styles.image_shade} />
			</div>
			<div className={styles.blurb_wrapper}>
				<p className={cx(styles.tag, styles.card_tag)}>{props.tag}</p>
				<p
					className={styles.blurb}
					dangerouslySetInnerHTML={{ __html: props.blurb }}
				/>
			</div>
		</article>
	)
}

const Award: FC<AwardProps> = (props) => {
	return (
		<article className={styles.award_container}>
			<div className={styles.award_text}>
				<p className={styles.award_year}>{props.year}</p>
				<p className={styles.award_title}>{props.title}</p>
			</div>

			<div className={styles.award_logo}>{props.logo}</div>
		</article>
	)
}
