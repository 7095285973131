import styles from './SlideOutBenefits.module.scss'
import { Img } from 'components/basic/Img'

export const HealthContent = () => {
	return (
		<div className={styles.modal_content}>
			<p className={styles.content_body}>Sleeping on the Pod for just one week can improve your cardiovascular health as much as 12 weeks of strength training.</p>

			<div className={styles.content_image_container}>
				<Img
					src={'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/benefits/Benefits+-+Health+2.jpeg'}
					alt={''}
				/>
			</div>

			<p
				className={styles.content_body}
				style={{ marginLeft: 'auto' }}
			>
				The Pod boosts your ability to recover by giving your body up to 14 more minutes of deep sleep each night.
			</p>

			<ul>
				<li>
					<BigStat
						num={2}
						text={'beats per min'}
						textClass={styles.eyebrow}
						caption={'Lowers your sleeping heart rate by up to 2 beats per minute on average. A lower sleeping heart rate is linked to longevity.'}
					/>
				</li>
				<li>
					<BigStat
						num={65}
						text={'%'}
						caption={'Users completed 65% more high-intensity exercises on average.'}
					/>
				</li>
				<li>
					<BigStat
						num={17}
						text={'%'}
						caption={'Lowers your sleeping heart rate by up to 2 beats per minute on average. A lower sleeping heart rate is linked to longevity.'}
					/>
				</li>
			</ul>
		</div>
	)
}

export const FocusContent = () => {
	return (
		<div className={styles.modal_content}>
			<p className={styles.content_body}>After using the Pod for just two days, participants in a study experienced positive changes in memory and attention.</p>

			<div
				className={styles.content_image_container}
				style={{
					marginLeft: 'auto',
				}}
			>
				<Img
					src={'https://eightsleep.imgix.net/benefits/Benefits+-+Focus+1.jpg?auto=format'}
					alt={''}
				/>
			</div>

			<ul>
				<li>
					<BigStat
						num={8}
						text={'%'}
						caption={'Improves response time by 8%, meaning responding faster to new things around you.'}
					/>
				</li>
				<li>
					<BigStat
						num={13}
						text={'%'}
						caption={'Enhances cognitive abilities related to decision-making, planning and task execution by 13%.'}
					/>
				</li>
				<li>
					<BigStat
						num={6}
						text={'%'}
						caption={'Improves short-term memory by 6%, making it easier to recall new information.'}
					/>
				</li>
			</ul>
		</div>
	)
}

export const PerformanceContent = () => {
	return (
		<div className={styles.modal_content}>
			<p className={styles.content_body}>After using the Pod for one month, users reported increased energy from falling asleep faster and sleeping better.</p>

			<div className={styles.content_image_container}>
				<Img
					src={'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/benefits/Benefits+-+Performance+2.jpeg'}
					alt={''}
				/>
			</div>

			<ul>
				<li>
					<BigStat
						num={34}
						text={'%'}
						caption={'Users experienced a 34% increase in their energy levels. More energy to do more of what you love.'}
					/>
				</li>
				<li>
					<BigStat
						num={23}
						text={'%'}
						caption={'Lowers the number of sleep disturbances by 23%. That means waking up less in the middle of the night.'}
					/>
				</li>
				<li>
					<BigStat
						num={44}
						text={'%'}
						caption={'Helps you fall asleep 44% faster, meaning less time awake in bed.'}
					/>
				</li>
			</ul>
		</div>
	)
}

const BigStat = (props: { num: number; text: string; caption: string; textClass?: string }) => {
	return (
		<div className={styles.big_stat}>
			<p className={styles.big_number}>
				{props.num}
				<span className={props.textClass || styles.small_unit}>{props.text}</span>
			</p>
			<p className={styles.caption}>{props.caption}</p>
		</div>
	)
}
